<template>
  <div>
    <v-row>
      <v-col
          v-for="(product,index) in products_sorted"
          :key="index"
          :sm="products.length == 1 ? 12 : 6"
          cols="12"
      >
        <product
            :product="product"
            :disabled="in_cart_overlay(product)"
            :selected="selected_product_checker(product)"
            @click="$emit('select',product)"
        ></product>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import product from './product.vue'
import { mapState } from 'vuex'
/*
@desc Product list to display in search page
*/
export default {
  props: ['selected_area', 'selected_product_checker', 'filter_dates'],
  components: { product },
  computed: {
    ...mapState(['cart']),
    products() {
      return Object.values(this.selected_area.products)
    },
    /*
    @desc Products are sorted depending on their availability adn price (with random for fairness)
    */
    products_sorted() {
      const reA = /[^a-zA-Z]/g
      const reN = /[^0-9]/g
      function sortAlphaNum(a, b) {
        const aA = a.replace(reA, "")
        const bA = b.replace(reA, "")
        if (aA === bA) {
          const aN = parseInt(a.replace(reN, ""), 10)
          const bN = parseInt(b.replace(reN, ""), 10)
          return aN === bN ? 0 : aN > bN ? 1 : -1
        } else {
          return aA > bA ? 1 : -1
        }
      }
      const score = (p1) => {
        return (p1.available ? 10000 : 0) + (p1.price / 100)
      }
      return this.products.sort((p1, p2) => {
        const s1 = score(p1)
        const s2 = score(p2)
        if (s1 == s2) return sortAlphaNum(p1.label, p2.label)
        return s2 - s1
      })
    },
    products_count() {
      return Object.keys(this.selected_area.products).length
    },
  },
  methods: {
    in_cart_overlay(product) {
      const in_cart_pds = this.cart.filter(pc => pc.product_id == product.id)
      if (!in_cart_pds.length) return false
      const filter_dates = this.filter_dates
      return in_cart_pds.reduce((a, in_cart) => {
        if (!a) return false
        const cart_dates = [new Date(in_cart.start_date * 1000), new Date(in_cart.end_date * 1000)]
        return (
            (cart_dates[0] > filter_dates[0] && cart_dates[0] < filter_dates[1]) ||
            (cart_dates[1] > filter_dates[0] && cart_dates[1] < filter_dates[1])
        )
      }, true)
    }
  }
}
</script>

<style>
</style>