<template>
    <span>
        <left-menu
            v-if="$is_mobile"
            :menu_data="menu_data"
            type='left_menu'
        ></left-menu>

        <v-app-bar
            app
            class='main_bar'
            color="white"
            :prominent="!$is_mobile"
            :elevation="0"
        >
            <div class="d-flex align-center">
                <router-link to="/">
                    <v-img
                        :class="['mt-3' , 'logo']"
                        contain
                        src="/images/kliteam_logo.png"
                        :width="$is_mobile ? 70 : 200"
                    />
                </router-link>
            </div>

            <!-- --------------------------------------- MIDDLE MENU -->
            <v-spacer></v-spacer>

            <router-link to="/">
                <v-btn
                    v-if="!$is_mobile  && routePath!=='/'"
                    id="fromHeaderButton"
                    color="primary"
                    class="mr-2"
                    style="max-height:35px;min-height:35px;margin-top:4px"
                    @click="changeFromHeader()"
                    large>
                          {{$t('left_menu.book')}}
                </v-btn>
              </router-link>

            <template v-if="!$is_mobile">
                <left-menu
                    :menu_data="menu_data"
                    type='top_menu'
                ></left-menu>

                <v-divider
                    vertical
                    class='ma-2 mt-1'
                    style="max-height:40px;min-height:40px"
                ></v-divider>
            </template>

            <!-- --------------------------------------- USERS / BOOKING -->

            <me></me>

            <v-menu v-if="!$is_mobile" v-model="languageMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="margin: 4px 0px 0px 8px;" v-bind="attrs" v-on="on" text plain>
                  <v-icon left>mdi-translate</v-icon>
                  {{ activeLang }}
                  <v-icon small right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list dense v-model="$i18n.locale">
                <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
                  <v-list-item-title>{{ lang.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-badge
                color="secondary"
                class='cart_badge'
                light
                overlap
                :content="cart_count"
                :value="cart_count"
            >
                <v-btn
                    icon
                    color='primary'
                    to='/cart'
                >
                    <v-icon>mdi-cart</v-icon>

                </v-btn>

            </v-badge>

            <left-menu
                v-if="$is_mobile"
                :menu_data="menu_data"
                type='button'
            ></left-menu>

        </v-app-bar>
    </span>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Me from './me.vue'
import LeftMenu from '@/components/left-menu.vue'
import {from_header} from "@/main";
import { useRoute } from 'vue-router';
/*
@desc Main common Header (wrapper for `me` button component & `left-menu` component)
*/
export default {
    name: 'Header',

    components: { Me, LeftMenu },

    data: () => ({
        menu_data: {
            open: false,
        },
        group: null,
        langs: [
          { title: 'English', value: 'en' },
          { title: 'Français', value: 'fr' }
        ],
        dialog: false,
        languageMenu: false
    }),

    computed: {
        ...mapGetters(['cart_count']),
        activeLang() {
          return this.$i18n.locale
        },
        routePath: function (){
          return this.$route.path
        }
    },
    methods: {
      handleMenuItemClick (lang) {
        this.activeLang = lang.title;
        if (this.$i18n.locale !== lang.value) this.$i18n.locale = lang.value
      },
      from_header() {
        return from_header
      },
      changeFromHeader(){
        from_header = true
      }
    }

}
</script>

<style>
.logo,
.main_bar {
    transition: all 0.3s;
}
.cart_badge .v-badge__badge {
    top: 1px !important;
    left: 25px !important;
}
</style>