<template>
    <v-card
        @click="$emit('click')"
        :elevation="elevation"
    >
        <v-card-title>
            <v-row style="max-width:calc(100% + 25px)">
                <v-col
                    cols="3"
                    v-if="!dense"
                >
                    <v-img
                        :src="orga.logo"
                        contain
                        height="150px"
                        max-width="300px"
                    />
                </v-col>
                <v-col>
                    {{orga.name}}
                    <template v-if="edit_orga">
                        <v-card
                            elevation="0"
                            :disabled="sending_update"
                            @click.stop=""
                        >
                            <v-text-field
                                v-model="edit_orga.address"
                                :label="$t('address')"
                            ></v-text-field>
                            <v-text-field
                                v-model="edit_orga.phoneNumber"
                                :label="$t('phonenumber')"
                            ></v-text-field>
                            <v-btn
                                @click.stop="$emit('update')"
                                color="primary"
                                :loading="sending_update"
                            >{{$t('validate')}}</v-btn>
                            <v-btn
                                @click.stop="$emit('cancel')"
                                color="primary"
                                text
                            >{{$t('cancel')}}</v-btn>
                        </v-card>
                    </template>
                    <template v-else>
                        <v-card-text class='pl-0 mb-0 pb-0'>
                            <v-icon class='mr-3'>mdi-domain</v-icon>{{orga.address}}
                        </v-card-text>
                        <v-card-text class='pl-0 mb-0 mt-0 pt-0 pb-0'>
                            <v-icon class='mr-3'>mdi-phone</v-icon>{{orga.phoneNumber}}
                        </v-card-text>
                        <!-- To edit with corresponding variable -->
                        <v-card-text class='pl-0 mt-0 pt-0'>
                            <v-icon class='mr-3'>mdi-email</v-icon>webmaster@kliteam.fr
                        </v-card-text>
                    </template>
                </v-col>
            </v-row>
            <slot></slot>
        </v-card-title>
    </v-card>
</template>

<script>
/*
@desc Organization UI card
*/
export default {
    props: ['orga', 'elevation', 'dense', 'edit_orga', 'sending_update']
}
</script>

<style>
</style>
